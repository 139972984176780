import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Router } from '@angular/router';
import { finalize, tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'environments/environment';

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
    constructor(
        private _router: Router,
        // private snackBar: MatSnackBar,
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!req.url.includes('assets')) {
            const baseUrl = environment.apiUrl;
            const apiReq = req.clone({ url: `${baseUrl}/${req.url}` });

            return next.handle(apiReq).pipe(
                tap(
                    (event: HttpEvent<any>) => {
                        if (event instanceof HttpResponse) {
                            // do stuff with response if you want
                        }
                    },
                    (err: any) => {
                        if (err instanceof HttpErrorResponse) {
                            if (err.status === 401) {
                                this._router.navigate(['sign-in']);
                            }
                            else if (err.status === 400 || err.status == 500) {

                            }
                            else if (err.status === 409) {

                            }
                            else if (err.status === 0) {

                            }
                        }
                    }
                ),
                finalize(() => setTimeout(() => this, 0))
            );
        }
        else {
            return next.handle(req);
        }
    }
}
