export interface Company {
    id: number;
    shortName: string;
    name: string;
    theme: string;
    mainColor: string;
  }
  export const COMPANIES: Array<Company> = [
    {
      id: 1,
      shortName: 'RDP_GRAINS',
      name: 'RDP Grains',
      theme: 'theme-default',
      mainColor: '#f57c00'
    }, {
      id: 2,
      shortName: 'RDP_VOERE',
      name: 'RDP Voere',
      theme: 'theme-yellow-light',
      mainColor: '#fdd835'
    }
  ];
  