import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { User } from 'app/core/user/user.types';
import { UserService } from 'app/core/user/user.service';
import { CurrentUser } from 'app/core/user/user.model';
import { AuthService } from 'app/core/auth/auth.service';
import { Company } from 'app/modules/auth/sign-in/sign-in.model';

@Component({
    selector     : 'classy-layout',
    templateUrl  : './classy.component.html',
    styleUrls: ['./classy.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ClassyLayoutComponent implements OnInit, OnDestroy
{
    isScreenSmall: boolean;
    navigation: Navigation;
    user: CurrentUser;
    companies: Array<Company>;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: NavigationService,
        private _userService: UserService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private authService: AuthService,
        
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number
    {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.authService.getCompanies().subscribe(result => {
            this.companies = result;
        });

        // Subscribe to navigation data
        this._navigationService.getNavigationDetails()
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
            });


        this.user =  this.authService.storedUser;


        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {

                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void
    {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if ( navigation )
        {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    getCompanyLogo(): string {
        if (!this.companies || this.companies.length === 0) {
            return '';
        }

        const companyId = this.authService.getCompanyId();

        if (!companyId) {
            return '';
        }

        const companyShortName = this.companies.find(c => c.id === companyId).shortName;
        return `assets/images/logo/${companyShortName}.jpg`
    }

    getCompanyName(): string {
        if (!this.companies || this.companies.length === 0) {
            return 'N/A';
        }

        const companyId = this.authService.getCompanyId();

        if (!companyId) {
            return 'N/A';
        }

        return this.companies.find(c => c.id === companyId).name;
    }

    switchCompany(): void {
        if (!this.companies || this.companies.length === 0) {
            return;
        }

        const companyId = this.authService.getCompanyId();
        const otherCompanyId = this.companies.find(c => c.id !== companyId).id;

        this.authService.setSwitchCompany(otherCompanyId).subscribe({
            next: () => {
                const redirectURL = this._activatedRoute.snapshot.queryParamMap.get('redirectURL') || '/signed-in-redirect';
                this._router.navigateByUrl(redirectURL);
            },
            error: (e) => {
                // this.signInForm.enable();
                // // Reset the form
                // this.signInNgForm.resetForm();
                // // Set the alert
                // this.alert = {
                //     type   : 'error',
                //     message: 'Wrong cell number or password'
                // };
                // // Show the alert
                // this.showAlert = true;
            }
        })
    }
}
