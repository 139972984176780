import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, of, tap } from 'rxjs';
import { Navigation } from 'app/core/navigation/navigation.types';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { compactNavigation, defaultNavigation, futuristicNavigation, horizontalNavigation } from 'app/mock-api/common/navigation/data';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

    private readonly _compactNavigation: FuseNavigationItem[] = compactNavigation;
    private readonly _defaultNavigation: FuseNavigationItem[] = defaultNavigation;
    private readonly _futuristicNavigation: FuseNavigationItem[] = futuristicNavigation;
    private readonly _horizontalNavigation: FuseNavigationItem[] = horizontalNavigation;
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation> {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Observable<Navigation> {
        return this._httpClient.get<Navigation>('api/common/navigation').pipe(
            tap((navigation) => {
                this._navigation.next(navigation);
            })
        );

    }

    public getNavigationDetails(): Observable<Navigation> {

        var navigation: Navigation = {
            compact: [],
            default: [],
            futuristic: [],
            horizontal: []
        };

        let defaultNavigation: FuseNavigationItem[] = [
            {
                id: 'welcome',
                title: 'Welcome',
                type: 'basic',
                link: '/welcome',
                //icon: 'face',
            }
        ];


        const safexItems: FuseNavigationItem[] = this.getSafexItems();
        if (safexItems.length > 0) {
            {
                defaultNavigation.push({
                    id: 'safex',
                    title: 'Safex',
                    subtitle: 'Manage M2M Pricing and Contracts',
                    type: 'group',
                    children: safexItems
                });
            }
        }


        // const administrationItems: FuseNavigationItem[] = this.getAdministrationItems();
        // if (administrationItems.length > 0) {
        //     {
        //         defaultNavigation.push({
        //             id: 'admin',
        //             title: 'Administration',
        //             subtitle: 'Master data & Settings',
        //             type: 'group',
        //             children: [
        //                 {
        //                     id: 'user-interface.forms',
        //                     title: 'Administration',
        //                     type: 'collapsable',
        //                     icon: 'mat_outline:settings',
        //                     children: administrationItems
        //                 }
        //             ]
        //         });
        //     }
        // }

        navigation.default = defaultNavigation;
        return of(navigation);
    }



    // private getAdministrationItems(): FuseNavigationItem[] {
    //     let items: FuseNavigationItem[] = [];
    //     items.push({
    //         id: 'user.list',
    //         title: 'Users',
    //         type: 'basic',
    //         icon: 'mat_outline:people',
    //         link: '/admin/users'
    //     },
    //     {
    //         id: 'clients.list',
    //         title: 'Clients',
    //         type: 'basic',
    //         icon: 'mat_outline:groups',
    //         link: '/admin/clients'
    //     });
    //     return items;
    // }

    private getSafexItems(): FuseNavigationItem[] {
        let items: FuseNavigationItem[] = [];
        items.push({
            id: 'm2mpricings.new',
            title: 'M2M Pricing',
            type: 'basic',
            icon: 'settings_ethernet',
            link: '/m2mpricings'
        }
        ,
        {
            id: 'contracts.new',
            title: 'Contracts',
            type: 'basic',
            icon: 'collections_bookmark',
            link: '/contract'
        }
        ,
        {
            id: 'report.new',
            title: 'Reports',
            type: 'basic',
            icon: 'feather:pen-tool',
            link: '/reports'
        }
        
        // ,
        // {
        //     id: 'projects.drawings',
        //     title: 'Drawing Register',
        //     type: 'basic',
        //     icon: 'feather:pen-tool',
        //     link: '/drawings/drawing-register'
        // }
        // },
        // {
        //     id: 'projects.drawings.file-directory',
        //     title: 'File Directory',
        //     type: 'basic',
        //     icon: 'mat_outline:account_tree',
        //     link: 'drawings/file-directory'
        // }
       );
        return items;
    }

}
