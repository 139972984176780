import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, Observable, of, Subject, switchMap, throwError } from 'rxjs';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { CurrentUser } from '../user/user.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { COMPANIES, Company } from 'app/modules/auth/sign-in/sign-in.model';
import { LoginResult } from './models/auth.models';
import { user } from 'app/mock-api/common/user/data';
//const jwtDecode = require('jwt-decode');


@Injectable()
export class AuthService
{
    tokenChangeSubject: Subject<void>;
    private _authenticated: boolean = false;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string)
    {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string
    {
        return localStorage.getItem('accessToken') ?? '';
    }

    get storedUser(): CurrentUser {
        const helper = new JwtHelperService();
        const decodedToken = helper.decodeToken(this.accessToken);
            if (decodedToken == null) {
            return null;
        }
        return {
            email: decodedToken.username,
            id: decodedToken.userId,
            name: decodedToken.given_name,
            username: decodedToken.unique_name,
            status: 'online',
            role: decodedToken.userRole,
            acsIdentityId: decodedToken.acsId,
        };
    }

    public userHasRole(role: number): boolean {
        const userRole = this.storedUser?.role;

        if (userRole == null || role == 0) {
            return false;
        }

        if (userRole == role) {
            return true;
        }
        return false;
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    forgotPassword(email: string): Observable<any> {
        const data = {
            email: email
        }
        return this._httpClient.post('authentication/forgot-password', data);
    }

    acceptTerms(): Observable<any> {
        return this._httpClient.put('User/AcceptTerms', {});
    }
    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(username: string, resetToken: string, password: string): Observable<any> {
        return this._httpClient.post('Authentication/reset-password', {
            password: password,
            userName: username,
            token: resetToken
        });
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(userName: string, password: string, companyId: number): Observable<any> {

        const headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this._httpClient.post('Authentication/token', { userName, password, companyId }, { headers }).pipe(
            switchMap((response: any) => {

                // Store the access token in the local storage
                this.accessToken = response.token;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = this.storedUser;


                // Return a new observable with the response
                return of(response);
            })
        );
    }

    setSwitchCompany(companyId: number): Observable<any> {

        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._httpClient.post<LoginResult>(`Authentication/Company/${companyId}`, {}).pipe(
            switchMap((response: any) => {

                // Store the access token in the local storage
                this.accessToken = response.token;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = this.storedUser;


                // Return a new observable with the response
                return of(response);
            })
        );
    }


    getCompanies(): Observable<Array<Company>> {
        return of(COMPANIES);
    }

    public getCompanyId(): number {
        const decoded = this.getDecodedToken();

        if (!decoded) {
            return null;
        }

        return +decoded.company_id;
    }

    public getUsername(): string {
        const decoded = this.getDecodedToken();

        if (!decoded) {
            return null;
        }

        return decoded.unique_name;
    }

   
    private getDecodedToken(): any {
        const token = this.getToken();

        if (!token) {
            return null;
        }
        const helper = new JwtHelperService();
        const decoded = helper.decodeToken(token);
        return decoded;
    }

    public getToken(): string | null {
        const token = localStorage.getItem('accessToken');

        if (token) {
            return token;
        }

        return null;
    }

    public switchCompany(companyId: number): Observable<LoginResult> {
        return this._httpClient.post<LoginResult>(`Authentication/Company/${companyId}`, {});
    }

    public setToken(token: string): void {
        localStorage.setItem('access_token', token);
        this.tokenChangeSubject.next();
    }
    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any>
    {
        // Sign in using the token
        return this._httpClient.post('api/auth/sign-in-with-token', {
            accessToken: this.accessToken
        }).pipe(
            catchError(() =>

                // Return false
                of(false)
            ),
            switchMap((response: any) => {

                // Replace the access token with the new one if it's available on
                // the response object.
                //
                // This is an added optional step for better security. Once you sign
                // in using the token, you should generate a new one on the server
                // side and attach it to the response object. Then the following
                // piece of code can replace the token with the refreshed one.
                if ( response.accessToken )
                {
                    this.accessToken = response.accessToken;
                }

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response.user;

                // Return true
                return of(true);
            })
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any>
    {
        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: any): Observable<any>
    {
        return this._httpClient.post('Authentication/register', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any>
    {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return of(false);
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }
        else {
            return of(true);
        }

        // If the access token exists and it didn't expire, sign in using it
        //return this.signInUsingToken();
    }
}
