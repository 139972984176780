import { NgModule } from '@angular/core';
import { FuseLoadingInterceptor } from '@fuse/services/loading/loading.interceptor';

@NgModule({
    providers: [
    ]
})
export class FuseLoadingModule
{
}
